@use "../../styles/mixins" as *;

.parallax {
//   position: relative;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 1px; /* Enables parallax */
  animation: fadeOut 1s ease-out forwards;
}

/* Layers */
.layer {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  transform-origin: center;
}

/* Sky Layer (Farthest Back) */
.sky {
  background-image: url("../../images/sky.webp");
  transform: translateZ(-2px) scale(3);
  z-index: 1;
  bottom: 20%;
}

/* Mountains Layer */
.mountains {
  background-image: url("../../images/mountains.webp");
  transform: translateZ(-1.5px) scale(2.5);
  z-index: 2;
  bottom: 15%;
}

.teck {
  background-image: url("../../images/teck.webp");
  z-index: 2;
  transform: translateZ(-1px) scale(2);
  bottom: 0;

  @include tablet {
  }
}

/* Skier Layer */
.skier {
  background-image: url("../../images/bkRmv.webp");
  transform: translateZ(-1px) scale(2);
  z-index: 3;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 25% center;
 


}

/* Overlay Text */
.parallax-text {
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 3.5rem;
  text-align: center;
  color: white;
  width: 100%;
  font-weight: 900;
  z-index: 2;
  color: #98e4ff;
  text-shadow: 1px 1px 0 #e0e0e0, 1px 2px 0 #e0e0e0, 1px 3px 0 #e0e0e0,
    1px 2px 0 #e0e0e0, 1px 5px 0 #e0e0e0, 1px 6px 0 #e0e0e0, 1px 3px 0 #e0e0e0,
    1px 8px 0 #e0e0e0, 5px 13px 15px white;

  @include tablet {
    font-size: 5rem;
    padding: 0 0 0 0.5rem;
    font-weight: 700;
  }

  @include desktop {
    font-size: 12rem;
    bottom: 50%;
  }
}
