@use "../../styles/mixins" as *;

.home {
  padding: 1rem 2rem 4rem;

  @include tablet {
    padding: 1rem 5rem 5rem;
  }

  @include desktop {
    padding: 5rem 10rem;
  }

  &__container {
    padding: 3rem;

    @include desktop {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding:  5rem 7rem;
    }
  }

  &__heading {
    font-weight: 300;
    font-size: 2rem;
    text-align: left;

    @include desktop {
      font-size: 3.5rem;
    }
  }

  &__copy {

    @include desktop {
      font-size: 1.2rem;
      width: 70%;
    }
  }

  &__image,
  &__imageBtm {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    height: 30rem;

    @include tablet {
      height: 40rem;
    }

  }

  &__image {
    background-image: url("../../images/bodie2.jpg");

  }

  &__imageBtm {
    background-image: url("../../images/mainImage.webp");
    background-position: center;
  }
}


